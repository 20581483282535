import * as React from "react";
import { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../../components/Layout";
import Helmet from "../../components/Helmet";
import "../../styles/aboutus.scss";
import { ImageConstants } from "../../components/constants/Imageconstants";
import { apiRoutes } from "../../constants/apiRoutes";
import axios from "axios";
import { createImage, createMarkup } from "../../utils";
export default function Home() {
  const seoData = {
    title: "About Festival Events Asbl | Explore Luxembourg's Culture",
    description:
      "Learn about Festival Events Asbl, your gateway to Luxembourg's arts, culture, and entertainment. Discover the richness of Luxembourg's history, technology, and gastronomy.",
    url: `${process.env.GATSBY_SITE_URL}`,
  };

  const [aboutdata, setAboutdata] = useState("");
  const [aboutusteams, setAboutusteams] = useState("");

  const fechaboutdata = async () => {
    try {
      const about = await axios.get(apiRoutes.Aboutuspage);

      setAboutdata(about?.data?.data?.attributes);
    } catch (error) {
      console.log(error);
    }
  };
  const fechaboutteamsdata = async () => {
    try {
      const teams = await axios.get(apiRoutes.Aboutustems);

      setAboutusteams(teams?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fechaboutdata();
    fechaboutteamsdata();
  }, []);

  return (
    <Layout>
      <Helmet
        title={seoData.title}
        description={seoData.description}
        url={seoData?.url}
      />
      <main>
        <section
          className="aboutHerosection"
          style={{
            backgroundImage: `url(${createImage(
              aboutdata?.header_image?.data?.attributes?.url
            )})`,
          }}
        >
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1>{aboutdata.header_test}</h1>
              </div>
            </div>
          </div>
        </section>
        <div className="about aboutus">
          <section className="film-festival">
            <h1 className="title">{aboutdata.background_header}</h1>
            <div className="container">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="content rich-editor">
                    <p
                      dangerouslySetInnerHTML={createMarkup(
                        aboutdata.background_text
                      )}
                    />
                  </div>
                </div>
                <div className="col-md-12 col-lg-6">
                  <div className="film-festival-media">
                    <figure>
                      <img
                        src={createImage(
                          aboutdata?.background_text_image?.data?.attributes
                            ?.url
                        )}
                        alt="about"
                        width={100}
                        height={100}
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

        <section className="we-Are">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <h1 className="title">{aboutdata.who_are_we}</h1>
              </div>
            </div>
            <div className="we-are-main">
              {aboutusteams?.data?.map((item, index) => {
                return (
                  <div className="wearebox" key={index}>
                    <figure>
                      <img
                        src={createImage(
                          item?.attributes?.profile_image?.data?.attributes?.url
                        )}
                        alt="we are one"
                      />
                    </figure>
                    <h5>{item.attributes.name}</h5>
                    <p>{item.attributes.position}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
}
